<script>
import InputField from '@/components/general/InputField'
export default {
  name: 'ValueSelection',
  components: { InputField },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        description: null,
        value: 10
      }
    }
  },
  methods: {
    submit () {
      this.$emit('input', this.formData)
      this.$emit('change', this.formData)
    }
  },
  created () {
    if (this.value) this.formData = this.value
  }
}
</script>
<template>
  <div class="value-selection--container">
    <div class="value-selection--header">
      <h2 class="value-selection--title">
        {{ $t('admin.center:value.selection.title') }}
      </h2>
    </div>
    <div class="value-selection--body">
      <input-field class="value-selection--description-input" hide-validation dense outlined background="#fff" :placeholder="$t('admin.center:value.selection.description.label')" v-model="formData.description"></input-field>
      <p class="value-selection--value-label">{{ $t('admin.center:value.selection.value') }}</p>
      <input-field type="number" class="value-selection--value-input" prefix="R$" hide-validation dense outlined background="#fff" v-model="formData.value"></input-field>
      <v-btn class="btn transform-unset" :disabled="!formData.description || !formData.value" :dark="!(!formData.description || !formData.value)" :color="getPrimaryColor" @click="submit">{{ $t('global:add') }}</v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.value-selection--container {
  .value-selection--header {
    margin: 40px 0;
    .value-selection--title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #212121;
    }
  }
  .value-selection--body {
    display: flex;
    align-items: center;
    .value-selection--description-input {
      width: 565px;
      margin-right: 16px;
    }
    .value-selection--value-label {
      margin: 0 8px 0 0;
    }
    .value-selection--value-input {
      width: 120px;
      margin-right: 16px;
    }
  }
}
</style>
