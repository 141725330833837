<script>
import HorizontalSteps from '@/components/general/HorizontalSteps'
import PaymentSelection from './PaymentSelection'
import ConclusionPayment from './ConclusionPayment'
import ValueSelection from './ValueSelection'
export default {
  name: 'ServicePayment',
  components: { HorizontalSteps, PaymentSelection, ConclusionPayment, ValueSelection },
  data () {
    return {
      horizontalSteps: [
        'add.credit:horizontal.steps.2',
        'add.credit:horizontal.steps.3',
        'add.credit:horizontal.steps.4'
      ],
      formData: {
        currency: null,
        value: {
          description: null,
          value: null
        },
        payment: null
      }
    }
  },
  computed: {
    actualStep () {
      if (this.formData.payment) return 2
      if (this.formData.value.description && this.formData.value.value) return 1
      return 0
    }
  }
}
</script>
<template>
  <section class="service-payment--container center-small">
    <div class="service-payment--header">
      <h2 class="service-payment-title">
        {{ $t('admin.center:service.payment.title') }}
      </h2>
    </div>
    <div class="service-payment--body">
      <horizontal-steps :value="actualStep" :steps="horizontalSteps"></horizontal-steps>
      <value-selection v-if="actualStep === 0" @change="value => formData.value = value"></value-selection>
      <payment-selection v-if="actualStep === 1" :isBRL="true" :credits="formData.value.value" :description="formData.value.description" :isServicePayment="true" @change="payment => formData.payment = payment"></payment-selection>
      <conclusion-payment v-if="actualStep === 2" isService></conclusion-payment>
    </div>
  </section>
</template>
<style lang="scss">
.service-payment--container {
  padding-top: 150px;
  padding-bottom: 100px;
  text-align: left;
  .service-payment--header {
    text-align: left;
    margin-bottom: 40px;
    .service-payment-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 16px;
      color: #272730;
    }
  }
}
</style>
